<template>
    <v-container class="section-role-cards" fluid>
        <div class="scroll-container">
            <v-row class="role-cards" justify="center" align="center" wrap>
                <v-col
                    v-for="role in roles"
                    :key="role.id"
                    cols="12"
                    md="6"
                    sm="4"
                    lg="3"
                    class="d-flex justify-center"
                >
                    <v-card :to="'/login'" class="role-card pa-6">
                        <v-card-title class="text-h4 text-center role-title">
                            {{ role.title }}
                        </v-card-title>
                        <v-card-text class="text-h5 text-center role-description">{{
                            role.description
                        }}</v-card-text>
                        <div class="icon-container">
                            <component :is="role.icon" />
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script setup>
import IconTutor from './icons/IconTutor.vue';
import IconCentro from './icons/IconCentro.vue';
import IconProfesional from './icons/IconProfesional.vue';

const roles = [
    {
        id: 1,
        title: "Tutor",
        description: "Acceso",
        icon: IconTutor,
    },
    {
        id: 2,
        title: "Profesional",
        description: "Acceso",
        icon: IconProfesional,
    },
    {
        id: 3,
        title: "Centro",
        description: "Acceso",
        icon: IconCentro,
    },
];
</script>

<style scoped>
* {
    font-family: "Montserrat", sans-serif;
}

.section-role-cards {
    height: 100%;
    width: 100%;
    padding: 20px;
}

.scroll-container {
    max-height: 50vh;
    overflow-y: auto;
}

.role-card {
    margin: 20px 10px;
    background-color: #fefefe;
    border: 1px solid #ddd;
    width: 100%;
    max-width: 550px;
    height: 400px;
    border-radius: 30px;
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: inherit;
}

.role-cards {
    margin: 0;
    padding: 0;
    gap: 10px;
}

.role-card:hover {
    transform: scale(1.02);
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 90%;
    background-color: #99cae8;
    width: 180px;
    height: 180px;
    margin: 0 auto;
}

.role-title {
    font-weight: 700;
}

.role-description {
    color: #999;
    font-weight: 400;
}
</style>
