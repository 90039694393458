<template>
    <svg
        width="96"
        height="96"
        viewBox="0 0 61 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_12_248)">
            <path
                d="M52.0668 39.4332C48.7448 36.1113 44.7908 33.6521 40.4767 32.1634C45.0972 28.9811 48.1328 23.6551 48.1328 17.6328C48.1328 7.9101 40.2227 0 30.5 0C20.7773 0 12.8672 7.9101 12.8672 17.6328C12.8672 23.6551 15.9028 28.9811 20.5234 32.1634C16.2093 33.6521 12.2553 36.1113 8.93328 39.4332C3.1726 45.194 0 52.8532 0 61H4.76562C4.76562 46.81 16.31 35.2656 30.5 35.2656C44.69 35.2656 56.2344 46.81 56.2344 61H61C61 52.8532 57.8274 45.194 52.0668 39.4332ZM30.5 30.5C23.4051 30.5 17.6328 24.7279 17.6328 17.6328C17.6328 10.5378 23.4051 4.76562 30.5 4.76562C37.5949 4.76562 43.3672 10.5378 43.3672 17.6328C43.3672 24.7279 37.5949 30.5 30.5 30.5Z"
                fill="#F2F2F2"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M37.7 49.2C38.6549 47.955 38.93 47.0333 38.93 45.8576C38.93 43.6663 37.3627 41.675 35.2401 41.675C33.4467 41.675 31.3849 43.6081 30.32 44.9889C29.2263 43.5578 27.1932 41.6597 25.4001 41.675C23.2198 41.6938 21.71 43.6663 21.71 45.8576C21.71 47.0331 22.0575 47.8914 22.9401 49.1627L30.32 58.3257L37.7 49.2Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_12_248">
                <rect width="61" height="61" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
