<template>
    <v-container class="landing-banner" fluid>
        <v-row justify="center">
            <v-col cols="12" md="8" class="text-center">
                <img class="logo" src="../assets/Vertical2.png" alt="Bluba Logo">
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
.landing-banner {
    background-color: #DDEEF9;
    height: 50vh;
    width: 100%;
    padding: 0;
}

.logo {
    width: 50%;
}
</style>
