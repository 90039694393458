<!-- src/components/Login.vue -->
<template>
    <v-container>
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card>
            <v-card-title class="headline">Login</v-card-title>
            <v-card-text>
              <v-form @submit.prevent="submitLogin" ref="form">
                <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  required
                ></v-text-field>
                <v-btn type="submit" color="primary" block>
                  Login
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        password: '',
        APP_DEBUG: process.env.VUE_APP_DEBUG,
        APP_API_URL: process.env.VUE_APP_API_URL
      }
    },
    methods: {
      submitLogin() {
        debugger
        if (this.$refs.form.validate()) {
          console.log('Email:', this.email);
          console.log('Password:', this.password);
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .v-card {
    max-width: 400px;
    margin: 0 auto;
  }
  </style>
  