<template>
    <svg
        width="96"
        height="96"
        viewBox="0 0 61 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M52.0668 39.4332C48.7448 36.1113 44.7908 33.6521 40.4767 32.1634C45.0972 28.9811 48.1328 23.6551 48.1328 17.6328C48.1328 7.9101 40.2227 0 30.5 0C20.7773 0 12.8672 7.9101 12.8672 17.6328C12.8672 23.6551 15.9028 28.9811 20.5234 32.1634C16.2093 33.6521 12.2553 36.1113 8.93328 39.4332C3.1726 45.194 0 52.8532 0 61H4.76562C4.76562 46.81 16.31 35.2656 30.5 35.2656C44.69 35.2656 56.2344 46.81 56.2344 61H61C61 52.8532 57.8274 45.194 52.0668 39.4332ZM30.5 30.5C23.4051 30.5 17.6328 24.7279 17.6328 17.6328C17.6328 10.5378 23.4051 4.76562 30.5 4.76562C37.5949 4.76562 43.3672 10.5378 43.3672 17.6328C43.3672 24.7279 37.5949 30.5 30.5 30.5Z"
            fill="#F2F2F2"
        />
    </svg>
</template>
